import {RegistryType} from './enums/registry-type.enum';

export class RegistryModel {
  constructor(
    public id?: number,
    public denumire?: string,
    public startNumber = 0,
    public currentNumber = 0,
    public prefix?: string,
    public sufix?: string,
    public type?: RegistryType,
    public pattern = 'NNNNN / ZZ/LL/AAAA',
    public dirty = false) {
  }
}
